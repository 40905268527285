<template>
  <div class="rate_list_box padding-md text-white">
    <div v-if="$route.path.lastIndexOf('/') === 0">
      <!-- 搜索框 -->
      <div class="box-card">
        <div class="text item">
          <el-form inline class="padding-top margin-sm-bottom">
            <el-form-item :label="$t('siteList.siteName')">
              <el-input
                v-model="searchForm.name"
                style="width: 250px"
                clearable
                placeholder="请输入站点名称"
              />
            </el-form-item>
            <el-form-item>
              <el-date-picker
                v-model="searchForm.dateValue"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button
                class="btn-purple"
                icon="el-icon-search"
                style="color: #000"
                @click="queryData()"
                v-preventReClick
                >{{ $t("siteList.checkButton") }}</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button
                class="btn-green"
                style="color: #000"
                icon="el-icon-download"
                @click="exportData()"
                v-preventReClick
                >导出</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
      <!-- 站点数据信息展示表格 -->
      <div class="table-box">
        <el-table
          :data="dataList"
          style="width: 100%"
          :cell-style="{ 'text-align': 'center' }"
          :header-cell-style="{ 'text-align': 'center' }"
          :row-class-name="tableRowClassName"
          :row-style="{ height: '60px' }"
          :show-overflow-tooltip="true"
        >
          <!-- <el-table-column label="站点ID" prop="siteId" width="160">
          </el-table-column> -->
          <el-table-column label="站点名称" prop="name" min-width="100">
          </el-table-column>
          <el-table-column label="时间" prop="historyTime" min-width="100">
          </el-table-column>
          <el-table-column
            label="充电支出（元）"
            prop="chargeExpend"
            min-width="100"
          >
            <template slot-scope="scope">
              {{ scope.row.chargeExpend | num }}
            </template>
          </el-table-column>
          <el-table-column
            label="放电收入（元）"
            prop="dischargeIncome"
            min-width="100"
          >
            <template slot-scope="scope">
              {{ scope.row.dischargeIncome | num }}
            </template>
          </el-table-column>
          <el-table-column
            label="总收益（元）"
            prop="benefitNum"
            min-width="100"
          >
            <template slot-scope="scope">
              {{ scope.row.benefitNum | num }}
            </template>
          </el-table-column>
          <el-table-column
            label="总充电量（kWh）"
            prop="totalChargeNum"
            min-width="100"
          >
            <template slot-scope="scope">
              {{ scope.row.totalChargeNum | num }}
            </template>
          </el-table-column>
          <el-table-column
            label="总放电量（kWh）"
            prop="totalDischargeNum"
            min-width="100"
          >
            <template slot-scope="scope">
              {{ scope.row.totalDischargeNum | num }}
            </template>
          </el-table-column>
          <el-table-column
            label="峰期放电量（kWh）"
            prop="highDischargeNum"
            min-width="100"
          >
            <template slot-scope="scope">
              {{ scope.row.highDischargeNum | num }}
            </template>
          </el-table-column>
          <el-table-column
            label="峰期充电量（kWh）"
            prop="highChargeNum"
            min-width="100"
          >
            <template slot-scope="scope">
              {{ scope.row.highChargeNum | num }}
            </template>
          </el-table-column>
          <el-table-column
            label="谷期放电量（kWh）"
            prop="lowDischargeNum"
            min-width="100"
          >
            <template slot-scope="scope">
              {{ scope.row.lowDischargeNum | num }}
            </template>
          </el-table-column>
          <el-table-column
            label="谷期充电量（kWh）"
            prop="lowChargeNum"
            min-width="100"
          >
            <template slot-scope="scope">
              {{ scope.row.lowChargeNum | num }}
            </template>
          </el-table-column>
          <el-table-column
            label="平期放电量（kWh）"
            prop="normalDischargeNum"
            min-width="100"
          >
            <template slot-scope="scope">
              {{ scope.row.normalDischargeNum | num }}
            </template>
          </el-table-column>
          <el-table-column
            label="平期充电量（kWh）"
            prop="normalChargeNum"
            min-width="100"
          >
            <template slot-scope="scope">
              {{ scope.row.normalChargeNum | num }}
            </template>
          </el-table-column>
          <el-table-column
            label="尖峰放电量（kWh）"
            prop="sharpDischargeNum"
            min-width="100"
          >
            <template slot-scope="scope">
              {{ scope.row.sharpDischargeNum | num }}
            </template>
          </el-table-column>
          <el-table-column
            label="尖峰充电量（kWh）"
            prop="sharpChargeNum"
            min-width="100"
          >
            <template slot-scope="scope">
              {{ scope.row.sharpChargeNum | num }}
            </template>
          </el-table-column>
          <el-table-column
            label="高尖峰放电量（kWh）"
            prop="highSharpDischargeNum"
            min-width="120"
          >
            <template slot-scope="scope">
              {{ scope.row.highSharpDischargeNum | num }}
            </template>
          </el-table-column>
          <el-table-column
            label="高尖峰充电量（kWh）"
            prop="sharpChargeNum"
            min-width="120"
          >
            <template slot-scope="scope">
              {{ scope.row.sharpChargeNum | num }}
            </template>
          </el-table-column>
        </el-table>
        <!-- 翻页 -->
        <div class="block" style="margin-top: 15px">
          <pagination
            :total="countNum"
            v-on:getCurrent="changeCurrent"
            v-on:getPageSize="changePage"
          ></pagination>
        </div>
      </div>
    </div>
    <rate-update ref="rateUpdate" @refresh="getData"></rate-update>
  </div>
</template>

<script>
import pagination from "../../components/pagination.vue";
import { queryListByDay } from "../../api/benefit";
import i18n from "@/lang";
import RateUpdate from "../../components/rateUpdate.vue";
import { exportExcel } from "@/excel/mainExport";
export default {
  components: { pagination, RateUpdate },
  data() {
    return {
      searchForm: {
        name: "",
        dateValue: "",
      },
      dataList: [],
      searchRules: {
        siteId: [
          { required: false, message: "请输入站点ID", trigger: "blur" },
          {
            pattern: /^[a-zA-Z][a-zA-Z0-9_]*$/,
            message: "用户ID以字母开头，且只能包含英文字母、数字、下划线",
          },
        ],
      },
      object: {
        pageNumber: 1,
        pageSize: 10,
      },
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页的数据条数
      role: 0, //角色
      countNum: 0,
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getData();
    },
    async exportData() {
      let date = null;
      if (this.searchForm.dateValue != "") {
        date = this.searchForm.dateValue;
      }
      let obj = {
        pageNumber: this.object.pageNumber,
        pageSize: this.object.pageSize,
        para: {
          name: this.searchForm.name,
          dateRange: date,
        },
      };
      const { data } = await queryListByDay(obj);
      // console.log(data.data.countNum, "55555555");
      obj.pageSize = data.data.countNum;
      const allData = await queryListByDay(obj);
      console.log(allData, "66666666");
      let tHeader = [
        { dataItem: "name", dataName: "站点名称" },
        { dataItem: "historyTime", dataName: "时间" },
        { dataItem: "chargeExpend", dataName: "充电支出" },
        { dataItem: "dischargeIncome", dataName: "放电收入" },
        { dataItem: "benefitNum", dataName: "总收益" },
        { dataItem: "totalChargeNum", dataName: "总充电量" },
        { dataItem: "totalDischargeNum", dataName: "总放电量" },
        { dataItem: "highDischargeNum", dataName: "峰期放电量" },
        { dataItem: "highChargeNum", dataName: "峰期充电量" },
        { dataItem: "lowDischargeNum", dataName: "谷期放电量" },
        { dataItem: "lowChargeNum", dataName: "谷期充电量" },
        { dataItem: "normalDischargeNum", dataName: "平期放电量" },
        { dataItem: "normalChargeNum", dataName: "平期充电量" },
        { dataItem: "sharpDischargeNum", dataName: "尖峰放电量" },
        { dataItem: "sharpChargeNum", dataName: "尖峰充电量" },
        { dataItem: "highSharpDischargeNum", dataName: "高尖峰放电量" },
        { dataItem: "sharpChargeNum", dataName: "高尖峰充电量" },
      ];
      exportExcel(tHeader, allData.data.data.list, "日账单");
    },
    getData() {
      this.queryData();
    },
    onAdd(type, row = {}) {
      this.$refs.rateUpdate.open(type, row);
    },
    queryData() {
      let date = null;
      if (this.searchForm.dateValue != "") {
        date = this.searchForm.dateValue;
      }
      let obj = {
        pageNumber: this.object.pageNumber,
        pageSize: this.object.pageSize,
        para: {
          name: this.searchForm.name,
          dateRange: date,
        },
      };
      queryListByDay(obj).then((res) => {
        if (res.data.data.list.length == 0) {
          this.$message({
            type: "warning",
            message: "暂无结果",
          });
        } else {
          this.countNum = res.data.data.countNum;
          res.data.data.list.forEach((v) => {
            v.historyTime = v.historyTime.substr(0, 10);
          });
          this.dataList = res.data.data.list;
        }
      });
    },
    toDevice(id) {
      this.$router.push({
        name: "DeviceList",
        query: {
          id: id,
        },
      });
    },
    changeCurrent(data) {
      this.object.pageNumber = data;
      this.currentPage = data;
      this.getData();
    },
    changePage(data) {
      this.object.pageSize = data;
      this.pageSize = data;
      console.log(this.object);
      this.getData();
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "";
      } else {
        return "warning-row";
      }
    },
  },
  flexWidth(prop, tableData, title, num = 0) {
    if (tableData.length === 0) {
      //表格没数据不做处理
      return;
    }
    let flexWidth = 0; //初始化表格列宽
    let columnContent = ""; //占位最宽的内容
    let canvas = document.createElement("canvas");
    let context = canvas.getContext("2d");
    context.font = "14px Microsoft YaHei";
    if (prop === "" && title) {
      //标题长内容少的，取标题的值,
      columnContent = title;
    } else {
      // 获取该列中占位最宽的内容
      let index = 0;
      for (let i = 0; i < tableData.length; i++) {
        const now_temp = tableData[i][prop] + "";
        const max_temp = tableData[index][prop] + "";
        const now_temp_w = context.measureText(now_temp).width;
        const max_temp_w = context.measureText(max_temp).width;
        if (now_temp_w > max_temp_w) {
          index = i;
        }
      }
      columnContent = tableData[index][prop];
      //比较占位最宽的值跟标题、标题为空的留出四个位置
      const column_w = context.measureText(columnContent).width;
      const title_w = context.measureText(title).width;
      if (column_w < title_w) {
        columnContent = title || "留四个字";
      }
    }
    // 计算最宽内容的列宽
    let width = context.measureText(columnContent);
    flexWidth = width.width + 40 + num;
    return flexWidth + "px";
  },
  filters: {
    num: (val, fix = 2) => {
      if (val < 0) {
        val = parseFloat(val);
        val = val.toFixed(fix); // 保留小数2位
        val = Math.abs(val);
        val = "" + val; // 转换成字符串
        var int = val.slice(0, fix * -1 - 1); // 拿到整数
        var ext = val.slice(fix * -1 - 1); // 获取到小数
        int = int.split("").reverse().join(""); // 翻转整数
        var temp = ""; // 临时变量
        for (var i = 0; i < int.length; i++) {
          temp += int[i];
          if ((i + 1) % 3 == 0 && i != int.length - 1) {
            temp += ","; // 每隔三个数字拼接一个逗号
          }
        }
        temp = temp.split("").reverse().join(""); // 加完逗号之后翻转
        temp = temp + ext; // 整数小数拼接
        return "-" + temp; // 返回
      } else if (val != "-" && val != "" && val != null && val > 0) {
        val = parseFloat(val);
        val = val.toFixed(fix); // 保留小数2位
        val = "" + val; // 转换成字符串
        int = val.slice(0, fix * -1 - 1); // 拿到整数
        ext = val.slice(fix * -1 - 1); // 获取到小数
        int = int.split("").reverse().join(""); // 翻转整数
        temp = ""; // 临时变量
        for (i = 0; i < int.length; i++) {
          temp += int[i];
          if ((i + 1) % 3 == 0 && i != int.length - 1) {
            temp += ","; // 每隔三个数字拼接一个逗号
          }
        }
        temp = temp.split("").reverse().join(""); // 加完逗号之后翻转
        temp = temp + ext; // 整数小数拼接
        return temp; // 返回
      } else if (val == 0 || val == "0") {
        return val;
      } else {
        return "-";
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.rate_list_box{
    width: 100%;
    box-sizing: border-box;
    height: calc(100vh - 130px);
    overflow: auto;
}
.table-box{
  padding: 20px;
    // background: linear-gradient(45deg, #35478a, #162257);
    background: #102135
    border-radius: 10px;
}
.table-add{
  margin-bottom: 20px
}
/deep/.el-table, .el-table tr, .el-table td, .el-table th {
    background-color: transparent!important;
}
</style>
